$(document).on('app_ready', function () {
    if (melon.boot.show_mvine_chat_on_desk && melon.user.has_role("Mvine User")) {

        // If on mobile, do not show the chat
        if (melon.is_mobile()) {
            return;
        }
        let main_section = $(document).find('.main-section');

        // Add bottom padding to the main section
        main_section.css('padding-bottom', '60px');

        let chat_element = $(document.createElement('div'));
        chat_element.addClass('mvine-chat');

        main_section.append(chat_element);

        melon.require("mvine_chat.bundle.jsx").then(() => {
            melon.mvine_chat = new melon.ui.MvineChat({
                wrapper: chat_element,
            });
        });
    }

});
import './templates/send_message.html';
import './timeline_button';
