
	melon.templates['send_message'] = `<div class="timeline-message-box" style="padding: 2px; overflow: hidden;">
    <div class="message-header"
        style="align-items: flex-start; margin-bottom: 10px; display: flex; flex-direction: column;">
        <div class="actions" style="flex-shrink: 0; display: flex; align-items: center; padding-bottom: 4px; padding-left: 2px;">

            {% if (is_direct_message) { %}
            <span style="font-weight: 500; font-size: 12px;">Direct Message with &nbsp;</span>
            {% } %}

            <a class="action-btn" href="/mvine/channel/{{channel_id}}" title="Open {{ channel_name }}" target="_blank"
                style="text-decoration: underline; font-weight: 500; font-size: 12px; margin: 0;">
                {% if (is_direct_message) { %}
                <!-- split the channel_name check the  userID with owner theone which is not same display it's full_name -->
                {%var users = channel_name.split('_') %}
                {% var user_one = users[0].trim()%}
                {% var user_two = users[1].trim()%}
                {% if (user_one === owner) { %}
                {{peer_user}}
                {% } else { %}
                {{peer_user}}
                {% }%}
                {%} else { %}
                {% if(type == "Open"){ %}
                <i class="fa fa-globe"></i>
                {% } else if(type == "Private"){ %}
                <i class="fa fa-lock"></i>
                {% } else if(type == "Public"){ %}
                <i class="fa fa-hashtag"></i>
                {% } %}
                {{ channel_name }}
                {%} %}
            </a>
            <span class="text-muted" style="font-size: 12px; margin-left: 2px;">
                &nbsp;- {{ comment_when(creation) }}
            </span>

        </div>
        <div class="user-info" style="display: flex; align-items: flex-start;">
            <div class="user-avatar" style="flex-shrink: 0;">
            {{ melon.avatar(owner, "avatar-medium") }}
            </div>
            <div class="user-details"
                style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 8px; width: 90%;">
                <span style="font-weight: bold;">
                    {{ full_name || melon.user.full_name(owner) }}
                </span>
                <span
                    style="font-weight: normal; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;">
                    {{text}}
                </span>
            </div>
        </div>
        </div>
        {% if (file && file.length) { %}
    <div style="margin-top: 10px">
        <div class="ellipsis flex">
            <a href="{%= encodeURI(file).replace(/#/g, \'%23\') %}" class="text-muted small" target="_blank"
                rel="noopener noreferrer">
                <svg viewBox="0 0 16 16" class="icon icon-xs" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14 7.66625L8.68679 12.8875C7.17736 14.3708 4.64151 14.3708 3.13208 12.8875C1.62264 11.4042 1.62264 8.91224 3.13208 7.42892L7.84151 2.80099C8.9283 1.733 10.6189 1.733 11.7057 2.80099C12.7925 3.86897 12.7925 5.53028 11.7057 6.59827L7.35849 10.8109C6.75472 11.4042 5.78868 11.4042 5.24528 10.8109C4.64151 10.2176 4.64151 9.26823 5.24528 8.73424L8.86792 5.17429"
                    stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" />
                </svg>
            {%= file.split("/").slice(-1)[0] %}
            <svg class="icon icon-xs" style="color: var(--yellow-300)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.077 1.45h-.055a3.356 3.356 0 00-3.387 3.322v.35H3.75a2 2 0 00-2 2v5.391a2 2 0 002 2h8.539a2 2 0 002-2V7.122a2 2 0 00-2-2h-.885v-.285A3.356 3.356 0 008.082 1.45h-.005zm2.327 3.672V4.83a2.356 2.356 0 00-2.33-2.38h-.06a2.356 2.356 0 00-2.38 2.33v.342h4.77zm-6.654 1a1 1 0 00-1 1v5.391a1 1 0 001 1h8.539a1 1 0 001-1V7.122a1 1 0 00-1-1H3.75zm4.27 4.269a.573.573 0 100-1.147.573.573 0 000 1.147zm1.573-.574a1.573 1.573 0 11-3.147 0 1.573 1.573 0 013.147 0z"
                    fill="currentColor" stroke="currentColor"></path>
            </svg>
            </a>
            </div>
    </div>
    {% } %}
</div>`;
